import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { Downloader }       from 'resources/services/downloader';

@inject(CustomHttpClient, Downloader)
export class PersonalAreaReportsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param downloader
     */
    constructor(httpClient, downloader) {
        this.httpClient = httpClient;
        this.downloader = downloader;
    }

    /**
     * Downloads applicability report
     */
    downloadApplicabilityReport(criteria, onSuccess, onError) {
        return this.httpClient.rawPost('legislation/client/reports/applicability-report', criteria)
            .then((response) => {
                if (response.status !== 200) {
                    return response.json().then(onError);
                }

                return response.blob().then(onSuccess);
            });
    }

    /**
     * Downloads applicability listing
     */
    downloadApplicabilityListing(criteria, onSuccess, onError) {
        return this.httpClient.rawPost('legislation/client/reports/applicability-listing', criteria)
            .then((response) => {
                if (response.status !== 200) {
                    return response.json().then(onError);
                }

                return response.blob().then(onSuccess);
            });
    }

    /**
     * Downloads conformity listing
     */
    downloadConformityListing(criteria, onSuccess, onError) {
        return this.httpClient.rawPost('legislation/client/reports/conformity-listing', criteria)
            .then((response) => {
                if (response.status !== 200) {
                    return response.json().then(onError);
                }

                return response.blob().then(onSuccess);
            });
    }

}
