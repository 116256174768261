import { FormSchema } from 'modules/legislation/diplomas/form-schema';

export class ClientDiplomaFormSchema extends FormSchema {

    /**
     * Returns general form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    generalSchema(viewModel) {
        this.commencement_date = {
            type:     'material-ui-date-picker',
            key:      'commencement_date',
            label:    'form.field.commencement-date',
            size:     4,
            required: false,
            settings: {
                selectYears:  100,
                selectMonths: true,
            },
        };

        this.internal_code = {
            type:     'text',
            key:      'internal_code',
            label:    'form.field.internal-code',
            size:     4,
            required: false,
        };

        this.country_id = {
            type:         'select2',
            key:          'country_id',
            label:        'form.field.country',
            size:         4,
            options:      [],
            remoteSource: this.countriesRepository.active.bind(this.countriesRepository),
            observers:    [
                (newValue, oldValue) => this.source_id.instance.fetchData().then((options) => this.source_id.instance.enable(options.length > 0)),
            ],
        };

        this.source_id = {
            type:                   'select2',
            key:                    'source_id',
            label:                  'form.field.source',
            size:                   4,
            options:                [],
            remoteSource:           this.sourcesRepository.activeByCountry.bind(this.sourcesRepository),
            remoteSourceParameters: () => viewModel.model.country_id,
            observers:              [
                (newValue, oldValue) => this.type_id.instance.fetchData().then((options) => this.type_id.instance.enable(options.length > 0)),
            ],
            attributes:             {
                disabled: !isNumeric(viewModel.model.country_id),
            },
        };

        this.type_id = {
            type:                   'select2',
            key:                    'type_id',
            label:                  'form.field.type',
            size:                   4,
            options:                [],
            remoteSource:           this.diplomaTypesRepository.activeBySources.bind(this.diplomaTypesRepository),
            remoteSourceParameters: () => [viewModel.model.source_id],
            attributes:             {
                disabled: !isNumeric(viewModel.model.source_id),
            },
            observers:              [
                () => this.updateDiplomaName(viewModel),
            ],
        };

        this.number = {
            type:      'text',
            key:       'number',
            label:     'form.field.number',
            size:      2,
            observers: [
                () => this.updateDiplomaName(viewModel),
            ],
        };

        this.published_at = {
            type:      'material-ui-date-picker',
            key:       'published_at',
            label:     'form.field.published-at',
            size:      4,
            settings:  {
                selectYears:  100,
                selectMonths: true,
            },
            observers: [
                () => this.updateDiplomaName(viewModel),
            ],
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.name',
            size:       6,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.issuing_entity = {
            type:     'text',
            key:      'issuing_entity',
            label:    'form.field.issuing-entity',
            size:     12,
            required: false,
        };

        this.resume = {
            type:     'textarea',
            key:      'resume',
            label:    'form.field.resume',
            size:     12,
            required: false,
        };

        this.notes = {
            type:     'textarea',
            key:      'notes',
            label:    'form.field.notes',
            size:     12,
            required: false,
        };

        this.keywords = {
            type:     'tags',
            key:      'keywords',
            label:    'form.field.keywords',
            size:     12,
            required: false,
        };

        this.viewing_document_file = {
            type:     'file-dropzone',
            key:      'viewing_document_file_id',
            label:    'form.field.viewing-document',
            size:     12,
            url:      viewModel.repository.fileUploadUri(),
            mockFile: viewModel.model.existing_viewing_document_file,
        };

        this.status_id = {
            type:         'select2',
            key:          'status_id',
            label:        'form.field.status',
            size:         4,
            options:      [],
            remoteSource: this.diplomaStatusesRepository.active.bind(this.diplomaStatusesRepository),
        };

        return [
            [this.commencement_date, this.internal_code],
            [this.country_id, this.source_id, this.type_id],
            [this.number, this.published_at, this.name],
            [this.issuing_entity],
            [this.resume],
            [this.notes],
            [this.keywords],
            [this.viewing_document_file],
            [this.status_id],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.client.diplomas.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }
}
