import { inject }                  from 'aurelia-framework';
import { AppContainer }            from 'resources/services/app-container';
import { BaseFormViewModel }       from 'base-form-view-model';
import { MyActionsRepository }     from 'modules/legislation/client/personal-area/my-actions/services/repository';
import { MyActionFilesRepository } from 'modules/legislation/client/personal-area/my-actions/services/files-repository';
import { FormSchema }              from 'modules/legislation/client/personal-area/my-actions/view/form-schema';

@inject(AppContainer, MyActionsRepository, MyActionFilesRepository, FormSchema)
export class ViewMyAction extends BaseFormViewModel {

    headerTitle = 'form.title.view-record';
    formId      = 'legislation-client-personal-area-my-actions-view-form';

    actionReplySchema   = {};
    globalButtonsSchema = {};

    alert = {};
    model = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filesRepository
     * @param formSchema
     */
    constructor(appContainer, repository, filesRepository, formSchema) {
        super(appContainer);

        this.repository      = repository;
        this.filesRepository = filesRepository;
        this.formSchema      = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.personal-area.my-plan-actions.manage',
            'legislation.client.personal-area.my-plan-actions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.repository.find(params.id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);

            this.actionReplySchema   = this.formSchema.actionReplySchema(this);
            this.globalButtonsSchema = this.formSchema.globalButtonsSchema(this);
        });
    }

}
