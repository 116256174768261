import { inject, transient }                      from 'aurelia-framework';
import { ApplicabilityTypesRepository }           from 'modules/legislation/management/applicability-types/services/repository';
import { ConformityEvaluationStatusesRepository } from 'modules/legislation/management/conformity-evaluation-statuses/services/repository';

@transient()
@inject(ApplicabilityTypesRepository, ConformityEvaluationStatusesRepository)
export class ConformityFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        applicable_sectors:              [],
        diploma_classifications:         [],
        conformity_evaluation_date_from: null,
        conformity_evaluation_date_to:   null,
        applicability_types:             [],
        conformity_evaluation_states:    [],
        include_actions:                 null,
        include_legal_connections:       null,
        include_resume:                  null,
    };

    /**
     * Constructor
     *
     * @param applicabilityTypesRepository
     * @param conformityEvaluationStatusesRepository
     */
    constructor(applicabilityTypesRepository, conformityEvaluationStatusesRepository) {
        this.applicabilityTypesRepository           = applicabilityTypesRepository;
        this.conformityEvaluationStatusesRepository = conformityEvaluationStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model() {
        return this.modelDefaults;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.applicable_sectors = {
            type:         'fancytree',
            key:          'applicable_sectors',
            id:           'conformity_applicable_sectors',
            label:        'form.field.applicable-sectors',
            size:         12,
            options:      [],
            remoteSource: () => Promise.resolve(viewModel.applicableSectorsTree),
            settings:     {
                repository: viewModel.companyOrganizationsRepository,
            },
        };

        this.diploma_classifications = {
            type:         'fancytree',
            key:          'diploma_classifications',
            id:           'conformity_diploma_classifications',
            label:        'form.field.classifications',
            size:         12,
            options:      [],
            remoteSource: () => Promise.resolve(viewModel.diplomaClassificationsTree),
            settings:     {
                repository: viewModel.diplomaClassificationsRepository,
                selectMode: 3,
            },
        };

        this.conformity_evaluation_date_from = {
            type:     'material-ui-date-picker',
            key:      'conformity_evaluation_date_from',
            id:       'conformity_conformity_evaluation_date_from',
            label:    'form.field.conformity-evaluation-date-from',
            size:     4,
            required: false,
        };

        this.conformity_evaluation_date_to = {
            type:     'material-ui-date-picker',
            key:      'conformity_evaluation_date_to',
            id:       'conformity_conformity_evaluation_date_to',
            label:    'form.field.conformity-evaluation-date-to',
            size:     4,
            required: false,
        };

        this.include_actions = {
            type:  'boolean-options-select',
            key:   'include_actions',
            id:    'conformity_include_actions',
            label: 'form.field.include-actions',
            size:  4,
        };

        this.include_legal_connections = {
            type:  'boolean-options-select',
            key:   'include_legal_connections',
            id:    'conformity_include_legal_connections',
            label: 'form.field.include-legal-connections',
            size:  4,
        };

        this.include_resume = {
            type:  'boolean-options-select',
            key:   'include_resume',
            id:    'conformity_include_resume',
            label: 'form.field.include-resume',
            size:  4,
        };

        this.applicability_types = {
            type:         'multiselect-native',
            key:          'applicability_types',
            id:           'conformity_applicability_types',
            label:        'form.field.applicability-types',
            size:         4,
            options:      [],
            remoteSource: this.applicabilityTypesRepository.active.bind(this.applicabilityTypesRepository),
        };

        this.conformity_evaluation_states = {
            type:         'multiselect-native',
            key:          'conformity_evaluation_states',
            id:           'conformity_conformity_evaluation_states',
            label:        'form.field.conformity-evaluation-statuses',
            size:         4,
            options:      [],
            remoteSource: this.conformityEvaluationStatusesRepository.activeWithNotEvaluated.bind(this.conformityEvaluationStatusesRepository),
            required:     false,
        };

        this.conformityListingButton = {
            type:       'submit',
            label:      'form.button.conformity-listing',
            action:     () => viewModel.downloadConformityListing(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-file-excel',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.conformityListingButton,
            ],
        };

        return [
            [this.applicable_sectors],
            [this.diploma_classifications],
            [this.conformity_evaluation_date_from, this.conformity_evaluation_date_to],
            [this.include_actions, this.include_legal_connections, this.include_resume],
            [this.applicability_types, this.conformity_evaluation_states],
            [this.buttons],
        ];
    }

}
