import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { Downloader }       from 'resources/services/downloader';

@inject(CustomHttpClient, Downloader)
export class DiplomasRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param downloader
     */
    constructor(httpClient, downloader) {
        this.httpClient = httpClient;

        this.downloader = downloader;
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/diplomas/search', criteria);
    }

    /**
     * Exports listing excel
     */
    exportListingExcel(criteria) {
        return this.httpClient.rawPost('legislation/diplomas/export-listing', criteria)
            .then((response) => response.blob())
            .then(result => this.downloader.download(result, 'diplomas_listing', 'xlsx'));
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get(`legislation/diplomas/${id}`);
    }

    /**
     * Opens a file
     *
     * @param file
     */
    openFile(file) {
        this.getFile(file.id)
            .then(result => this.downloader.open(result, file.display_name, file.extension));
    }

    /**
     * Downloads a file
     *
     * @param file
     */
    downloadFile(file) {
        this.getFile(file.id)
            .then(result => this.downloader.download(result, file.display_name, file.extension));
    }

    /**
     * Gets a file
     *
     * @param id
     *
     * @returns {*}
     */
    getFile(id) {
        return this.httpClient.rawGet(`legislation/diplomas/files/${id}/preview`)
            .then((response) => response.blob());
    }

}
