import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';
import { Downloader }       from 'resources/services/downloader';

@inject(CustomHttpClient, Downloader)
export class MyActionsRepository {

    /**
     * Constructor
     *
     * @param httpClient
     * @param downloader
     */
    constructor(httpClient, downloader) {
        this.httpClient = httpClient;

        this.downloader = downloader;
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.httpClient.post('legislation/client/personal-area/my-actions/search', criteria);
    }

    /**
     * Exports listing excel
     */
    exportListingExcel(criteria) {
        return this.httpClient.rawPost('legislation/client/personal-area/my-actions/export-listing', criteria)
            .then(response => {
                if (response.status === 200) {
                    return response.blob().then((excel) => this.downloader.download(excel, 'plan_actions_listing', 'xlsx'));
                }
            });
    }

    /**
     * Finds a record
     *
     * @param id
     *
     * @returns {*}
     */
    find(id) {
        return this.httpClient.get('legislation/client/personal-area/my-actions/' + id);
    }

    /**
     * Updates a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(id, data) {
        return this.httpClient.put('legislation/client/personal-area/my-actions/' + id, data);
    }

    /**
     * Cancels a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    cancel(id, data) {
        return this.httpClient.put('legislation/client/personal-area/my-actions/' + id + '/cancel', data);
    }

    /**
     * Concludes a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    conclude(id, data) {
        return this.httpClient.put('legislation/client/personal-area/my-actions/' + id + '/conclude', data);
    }

}
