import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseViewModel }              from 'base-view-model';
import { MyActionsRepository }        from 'modules/legislation/client/personal-area/my-actions/services/repository';
import { DiplomasRepository }         from 'modules/legislation/diplomas/services/repository';
import { PlanActionTaskStatus }       from 'modules/legislation/models/plan-action-task-status';
import { FilterFormSchema }           from 'modules/legislation/client/personal-area/my-actions/filter-form-schema';
import { DialogService }              from 'aurelia-dialog';
import moment                         from 'moment';

@inject(AppContainer, MyActionsRepository, DiplomasRepository, DialogService, FilterFormSchema)
export class ListMyPlanActions extends BaseViewModel {

    headerTitle = 'listing.legislation.my-actions';
    listingId   = 'legislation-client-personal-area-my-actions-listing';

    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param diplomasRepository
     * @param dialogService
     * @param filterFormSchema
     */
    constructor(appContainer, repository, diplomasRepository, dialogService, filterFormSchema) {
        super(appContainer);

        this.repository         = repository;
        this.diplomasRepository = diplomasRepository;
        this.dialogService      = dialogService;
        this.filterFormSchema   = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.personal-area.my-plan-actions.manage',
            'legislation.client.personal-area.my-plan-actions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            actionsContextMenu: true,
            repository:         this.repository,
            show:               {
                action: (row) => this.appContainer.router.navigateToRoute('legislation.client.personal-area.my-actions.view', {id: row.id}),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('legislation.client.personal-area.my-actions.edit', {id: row.id}),
                visible: (row) => row.canEdit,
            },
            destroy:            null,
            destroySelected:    false,
            actions:            [
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma',
                    visible: (row) => row.viewing_document_file_id,
                    action:  (row) => this.diplomasRepository.downloadFile(row.viewingDocument),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.consolidated-document',
                    visible: (row) => row.consolidated_document_file_id,
                    action:  (row) => this.diplomasRepository.downloadFile(row.consolidatedDocument),
                },
            ],
            options:            [
                {
                    label:     'form.button.export-to-excel',
                    icon:      'icon-file-excel',
                    className: 'btn bg-success',
                    action:    () => this.repository.exportListingExcel(this.filterModel),
                },
            ],
            buttons:            [],
            selectable:         true,
            sorting:            {
                column:    5,
                direction: 'desc',
            },
            columns:            [
                {
                    data:  'order',
                    name:  'plan_actions.order',
                    title: '#',
                },
                {
                    data:      'description',
                    name:      'plan_actions.description',
                    title:     'form.field.description',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/client/personal-area/my-actions/custom-listing-cells/plan-action-description/index'),
                },
                {
                    data:   'diploma',
                    name:   'diplomas.name',
                    title:  'form.field.diploma',
                    nowrap: true,
                },
                {
                    data:  'applicable_sector',
                    name:  'company_organizations.acronym',
                    title: 'form.field.applicable-sector',
                },
                {
                    data:  'implementation_deadline',
                    name:  'plan_action_tasks.implementation_deadline',
                    title: 'form.field.implementation-deadline',
                },
                {
                    data:  'implemented_at',
                    name:  'plan_action_tasks.implemented_at',
                    title: 'form.field.implementation-date',
                },
                {
                    data:  'responsible',
                    name:  'users.name',
                    title: 'form.field.responsible',
                },
                {
                    data:  'conformity_status',
                    name:  'plan_action_conformity_statuses.name',
                    title: 'form.field.conformity',
                    type:  'label',
                },
                {
                    data:  'status',
                    name:  'plan_action_task_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
                {
                    data:       'semaphore',
                    name:       'semaphore',
                    title:      '',
                    type:       'stoplight',
                    color_eval: (row) => {
                        let color     = null;
                        let now       = moment();
                        let semaphore = row.meta.semaphore;

                        if (semaphore && row.status.id === PlanActionTaskStatus.ASSIGNED) {
                            if (now.isAfter(moment(row.implementation_deadline).subtract(semaphore.red_time_interval_amount, semaphore.red_time_interval.php_slug))) {
                                color = 'text-danger';
                            } else if (now.isAfter(moment(row.implementation_deadline).subtract(semaphore.orange_time_interval_amount, semaphore.orange_time_interval.php_slug))) {
                                color = 'text-warning';
                            } else if (now.isAfter(moment(row.implementation_deadline).subtract(semaphore.green_time_interval_amount, semaphore.green_time_interval.php_slug))) {
                                color = 'text-success';
                            }
                        }

                        return color;
                    },
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

}
