import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseFormViewModel }             from 'base-form-view-model';
import { MyActionFollowupsRepository }   from 'modules/legislation/client/personal-area/my-actions/followups/services/repository';
import { FormSchema }                    from 'modules/legislation/client/personal-area/my-actions/followups/form-schema';

@inject(AppContainer, MyActionFollowupsRepository, FormSchema)
export class MyActionFollowupsListingCustomElement extends BaseFormViewModel {

    @bindable({defaultBindingMode: bindingMode.twoWay}) myAction;
    @bindable({defaultBindingMode: bindingMode.twoWay}) readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();

        this.initialModel = this.formSchema.model(this);
        this.model        = this.formSchema.model(this);

        this.schema = this.formSchema.schema(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.repository.search(this.myAction.id, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            destroy:         !this.readonly,
            destroySelected: !this.readonly,
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      !this.readonly,
            sorting:         {
                column:    1,
                direction: 'desc',
            },
            columns:         [
                {
                    data:  'overview',
                    name:  'plan_action_task_followups.overview',
                    title: 'form.field.overview',
                },
                {
                    data:            'created_at',
                    name:            'plan_action_task_followups.created_at',
                    title:           'form.field.date',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'creator',
                    name:  'users.name',
                    title: 'form.field.user',
                },
            ],
        };
    }

    /**
     * Submits form
     */
    submit() {
        this.alert = null;

        // calls repository create method
        this.repository.create(this.myAction.id, this.model)
            .then((response) => {
                // creates a new alert message
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {

                    this.resetForm();

                    this.datatable.instance.reload();
                }
            })
            .catch((error) => console.log(error));
    }

}
