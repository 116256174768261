import { bindable, bindingMode, inject } from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { DialogController }              from 'aurelia-dialog';
import { FormSchema }                    from 'modules/legislation/client/personal-area/my-actions/edit/conclude/form-schema';

@inject(AppContainer, DialogController, FormSchema)
export class ConcludeTaskModal {

    @bindable({defaultBindingMode: bindingMode.twoWay}) task;

    @bindable settings = {
        title: 'form.title.conclude-task',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, dialogController, formSchema) {
        this.appContainer     = appContainer;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
    }

    /**
     * Handles bind event
     */
    activate(task) {
        this.task = task;

        this.model  = this.formSchema.model();
        this.schema = this.formSchema.schema(this);

        this.model.next_task_implementation_deadline = this.task.next_task_implementation_deadline;
    }

    /**
     * Handles form submission
     */
    submit() {
        return new Promise((resolve, reject) => {
            this.task.create_next_task                  = this.model.create_next_task;
            this.task.next_task_implementation_deadline = this.model.next_task_implementation_deadline;

            resolve(true);
        });
    }

}
