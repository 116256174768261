import { inject }                                 from 'aurelia-framework';
import { PlanActionConformityStatusesRepository } from 'modules/legislation/management/plan-action-conformity-statuses/services/repository';
import { MyAction }                               from 'modules/legislation/client/personal-area/models/my-action';

@inject(PlanActionConformityStatusesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param planActionConformityStatusesRepository
     */
    constructor(planActionConformityStatusesRepository) {
        this.planActionConformityStatusesRepository = planActionConformityStatusesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {MyAction}
     */
    model() {
        let model = new MyAction();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns action reply form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    actionReplySchema(viewModel) {
        this.conformity_status_id = {
            type:         'select2',
            key:          'conformity_status_id',
            label:        'form.field.conformity',
            size:         4,
            options:      [],
            remoteSource: this.planActionConformityStatusesRepository.active.bind(this.planActionConformityStatusesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.implemented_at = {
            key:        'implemented_at',
            type:       'material-ui-date-picker',
            label:      'form.field.implementation-date',
            size:       4,
            attributes: {
                disabled: true,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            attributes: {
                disabled: true,
            },
        };

        return [
            [this.conformity_status_id, this.implemented_at],
            [this.observations],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.client.personal-area.my-actions.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
