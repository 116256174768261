import { inject, transient }                from 'aurelia-framework';
import { BaseFilterFormSchema }             from 'resources/classes/base-filter-form-schema';
import { LocalStorage }                     from 'resources/services/local-storage';
import { EventAggregator }                  from 'aurelia-event-aggregator';
import { I18N }                             from 'aurelia-i18n';
import { DialogService }                    from 'aurelia-dialog';
import { UsersRepository }                  from 'modules/administration/users/services/repository';
import { PlanActionTaskStatusesRepository } from 'modules/legislation/management/plan-action-task-statuses/services/repository';
import { PlanActionTaskStatus }             from 'modules/legislation/models/plan-action-task-status';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { AuthenticatedUser }                from 'resources/services/authenticated-user';
import { TreeModal }                        from 'modules/administration/tree-modal';

@transient()
@inject(
    LocalStorage,
    EventAggregator,
    I18N,
    DialogService,
    UsersRepository,
    PlanActionTaskStatusesRepository,
    AuthenticatedUser,
    DiplomaClassificationsRepository,
)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        implementation_deadline_from: null,
        implementation_deadline_to:   null,
        responsible_id:               null,
        status_id:                    [PlanActionTaskStatus.ASSIGNED],
        diploma_name:                 null,
        diploma_classification_id:    null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     * @param usersRepository
     * @param planActionTaskStatusesRepository
     * @param authenticatedUser
     * @param diplomaClassificationsRepository
     */
    constructor(
        storage,
        eventAggregator,
        i18n,
        dialogService,
        usersRepository,
        planActionTaskStatusesRepository,
        authenticatedUser,
        diplomaClassificationsRepository,
    ) {
        super(storage, eventAggregator, i18n);

        this.dialogService                    = dialogService;
        this.usersRepository                  = usersRepository;
        this.planActionTaskStatusesRepository = planActionTaskStatusesRepository;
        this.authenticatedUser                = authenticatedUser;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;

        if (this.authenticatedUser.currentTenant() && this.authenticatedUser.isClient()) {
            this.modelDefaults.responsible_id = this.authenticatedUser.user.id;
        }
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.diploma_name = {
            type:     'text',
            key:      'diploma_name',
            label:    'form.field.diploma',
            size:     3,
            required: false,
        };

        this.diploma_classification_id = {
            type:       'diploma-classifications-select',
            key:        'diploma_classification_id',
            label:      'form.field.classification',
            size:       6,
            required:   false,
            inputGroup: {
                position: 'right',
                button:   {
                    action: () => this.dialogService.open({
                        viewModel: TreeModal,
                        model:     {
                            repository: {
                                method: 'tree',
                                source: this.diplomaClassificationsRepository,
                            },
                            onClick:    (event, data) => viewModel.filterModel.diploma_classification_id = data.node.key,
                        },
                    }),
                    icon:   {
                        class: 'icon-tree5',
                    },
                },
            },
        };

        this.responsible_id = {
            type:         'select2',
            key:          'responsible_id',
            label:        'form.field.responsible',
            size:         3,
            remoteSource: this.usersRepository.all.bind(this.usersRepository),
            required:     false,
            hidden:       !this.authenticatedUser.can('legislation.client.personal-area.my-plan-actions.manage'),
        };

        this.implemented_at_from = {
            type:     'material-ui-date-picker',
            key:      'implemented_at_from',
            label:    'form.field.implementation-date(from)',
            size:     3,
            required: false,
        };

        this.implemented_at_to = {
            type:     'material-ui-date-picker',
            key:      'implemented_at_to',
            label:    'form.field.implementation-date(to)',
            size:     3,
            required: false,
        };

        this.implementation_deadline_from = {
            type:     'material-ui-date-picker',
            key:      'implementation_deadline_from',
            label:    'form.field.implementation-deadline-from',
            size:     3,
            required: false,
        };

        this.implementation_deadline_to = {
            type:     'material-ui-date-picker',
            key:      'implementation_deadline_to',
            label:    'form.field.implementation-deadline-to',
            size:     3,
            required: false,
        };

        this.status_id = {
            type:         'multiselect-native',
            key:          'status_id',
            label:        'form.field.status',
            size:         3,
            remoteSource: this.planActionTaskStatusesRepository.active.bind(this.planActionTaskStatusesRepository),
            required:     false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.diploma_name, this.diploma_classification_id, this.responsible_id],
            [this.implementation_deadline_from, this.implementation_deadline_to, this.implemented_at_from, this.implemented_at_to],
            [this.status_id],
            [this.buttons],
        ];
    }

}
