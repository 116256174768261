import { bindable, computedFrom, inject }   from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { BaseViewModel }                    from 'base-view-model';
import { CountriesRepository }              from 'modules/administration/countries/services/repository';
import { DiplomasRepository }               from 'modules/legislation/client/personal-area/diplomas/services/repository';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { DiplomaSettingsRepository }        from 'modules/legislation/management/diploma-settings/services/repository';
import { FilterFormSchema }                 from 'modules/legislation/client/personal-area/diplomas/filter-form-schema';
import { ViewDiplomaModal }                 from 'modules/legislation/diplomas/view-modal/index';
import { DialogService }                    from 'aurelia-dialog';

@inject(AppContainer, DiplomasRepository, CountriesRepository, DiplomaClassificationsRepository, DiplomaSettingsRepository, FilterFormSchema, DialogService)
export class ListDiplomas extends BaseViewModel {

    headerTitle = 'listing.legislation.diplomas';
    listingId   = 'legislation-client-personal-area-diplomas-listing';

    @bindable diplomaClassificationsTree = {
        click:    this.diplomaClassificationsTreeNodeClicked.bind(this),
        activate: this.diplomaClassificationsTreeNodeActivated.bind(this),
    };

    @bindable countrySettings = {
        hasEmptyOption: true,
        pluginOptions:  {
            minimumResultsForSearch: 10,
        },
    };

    @bindable displayDiplomasAboutSettings = {
        hasEmptyOption: true,
        pluginOptions:  {
            minimumResultsForSearch: 10,
        },
    };

    diplomas = [];
    settings = [];

    pages          = 1;
    page           = 1;
    resultsPerPage = 10;

    limit = {
        items:    [
            {id: 10, name: '10'},
            {id: 25, name: '25'},
            {id: 50, name: '50'},
            {id: 100, name: '100'},
        ],
        settings: {
            pluginOptions: {
                minimumResultsForSearch: 10,
            },
        },
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param countriesRepository
     * @param diplomaClassificationsRepository
     * @param diplomaSettingsRepository
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, countriesRepository, diplomaClassificationsRepository, diplomaSettingsRepository, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository                       = repository;
        this.countriesRepository              = countriesRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.diplomaSettingsRepository        = diplomaSettingsRepository;
        this.filterFormSchema                 = filterFormSchema;
        this.dialogService                    = dialogService;

        this.diplomaClassificationsTree.repository = {
            source:     this.diplomaClassificationsRepository,
            parameters: this.diplomaClassificationsTreeParameters.bind(this),
            method:     'treeByCountry',
        };
    }

    @computedFrom('page', 'resultsPerPage')
    get pagerStart() {
        if (!this.diplomas.length) {
            return 0;
        }

        return this.page * this.resultsPerPage - this.resultsPerPage + 1;
    }

    @computedFrom('page', 'resultsPerPage')
    get pagerEnd() {
        return Math.min(this.page * this.resultsPerPage, this.pagerFiltered);
    }

    /**
     * Returns `display diplomas about` dropdown items
     *
     * @returns {*[]}
     */
    get displayDiplomasAboutDropdownItems() {
        return [
            {
                id:   1,
                name: this.appContainer.i18n.tr('text.all-legislation'),
            },
            {
                id:   2,
                name: this.appContainer.i18n.tr('text.only-applicable-legislation'),
            },
        ];
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.personal-area.diplomas.manage',
            'legislation.client.personal-area.diplomas.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        return this.fetchData().then(() => {
            this.filterModel  = this.filterFormSchema.model(this);
            this.filterSchema = this.filterFormSchema.diplomaSchema(this);

            this.filterModel.number = params.Number;
            this.filterModel.year   = params.Year;

            return this.load();
        });
    }

    /**
     * Subscribers observers
     */
    subscribeObservers() {
        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.filterModel, 'country_id')
                .subscribe(() => {
                    this.load();
                    this.filterFormSchema.source_id.instance.fetchData();
                    this.diplomaClassificationsTree.instance.reload();
                }),
            this.appContainer
                .bindingEngine
                .propertyObserver(this.filterModel, 'display_diplomas_about')
                .subscribe(() => this.load()),
            this.appContainer
                .bindingEngine
                .propertyObserver(this, 'resultsPerPage')
                .subscribe(() => {
                    this.page = 1; // sets page to 1, and triggers this.pageChanged() (reload data).

                    if (this.page === 1) {
                        this.load(); // this.pageChanged() won't trigger if the current page is already page 1.
                    }
                }),
        );
    }

    /**
     * Returns diploma classifications tree parameters
     */
    diplomaClassificationsTreeParameters() {
        return this.filterModel.country_id;
    }

    /**
     * Handles tree node click event
     *
     * @param event
     * @param data
     */
    diplomaClassificationsTreeNodeClicked(event, data) {
        let activeNode = data.tree.getActiveNode();

        if (activeNode === data.node) {
            activeNode.setFocus(false);
            activeNode.setActive(false);

            this.filterModel.diploma_classification_id = null;

            this.load();

            return false;
        }
    }

    /**
     * Handles tree node activate event
     *
     * @param event
     * @param data
     */
    diplomaClassificationsTreeNodeActivated(event, data) {
        this.filterModel.diploma_classification_id = data.node.key;

        this.load();
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise<Array>}
     */
    fetchData(params = null) {
        return Promise.all([
            this.countriesRepository.active().then((countries) => this.countries = countries),
            this.diplomaSettingsRepository.settings().then((settings) => this.settings = settings),
        ]);
    }

    /**
     * Performs search
     */
    load() {
        this.filterModel.start  = (this.page - 1) * this.resultsPerPage;
        this.filterModel.length = this.resultsPerPage;

        this.repository
            .search(this.filterModel)
            .then(response => {
                this.pagerTotal    = response.recordsTotal;
                this.pagerFiltered = response.recordsFiltered;
                this.pages         = Math.ceil(response.recordsFiltered / this.resultsPerPage);

                this.diplomas.splice(0, this.diplomas.length, ...response.data);
            })
            .catch((error) => this.notifier.dangerNotice(this.i18n.tr('text.error-message.action-failed')));
    }

    /**
     * Shows a diploma
     *
     * @param diploma
     */
    showDiploma(diploma) {
        this.dialogService.open({
            viewModel: ViewDiplomaModal,
            model:     diploma,
        });
    }

}
