import { BaseModel } from 'modules/core/models/base-model';

export class MyAction extends BaseModel {

    conformity_status_id              = null;
    implemented_at                    = null;
    observations                      = null;
    // temporary fields
    create_next_task                  = null;
    next_task_implementation_deadline = null;

}
