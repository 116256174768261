import { inject }                           from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { BaseFormViewModel }                from 'base-form-view-model';
import { PersonalAreaReportsRepository }    from 'modules/legislation/client/reports/services/repository';
import { CompanyOrganizationsRepository }   from 'modules/administration/company-organizations/services/repository';
import { DiplomaClassificationsRepository } from 'modules/legislation/management/diploma-classifications/services/repository';
import { ApplicabilityFormSchema }          from 'modules/legislation/client/reports/applicability-form-schema';
import { ConformityFormSchema }             from 'modules/legislation/client/reports/conformity-form-schema';
import { Downloader }                       from 'resources/services/downloader';

@inject(AppContainer, PersonalAreaReportsRepository, CompanyOrganizationsRepository, DiplomaClassificationsRepository, ApplicabilityFormSchema, ConformityFormSchema, Downloader)
export class PersonalAreaReports extends BaseFormViewModel {

    headerTitle = 'form.title.reports';

    applicabilityFormId = 'legislation-client-reports-applicability-report';
    conformityFormId    = 'legislation-client-reports-conformity-report';

    applicableSectorsTree      = [];
    diplomaClassificationsTree = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param companyOrganizationsRepository
     * @param diplomaClassificationsRepository
     * @param applicabilityFormSchema
     * @param conformityFormSchema
     * @param downloader
     */
    constructor(appContainer, repository, companyOrganizationsRepository, diplomaClassificationsRepository, applicabilityFormSchema, conformityFormSchema, downloader) {
        super(appContainer);

        this.repository                       = repository;
        this.companyOrganizationsRepository   = companyOrganizationsRepository;
        this.diplomaClassificationsRepository = diplomaClassificationsRepository;
        this.applicabilityFormSchema          = applicabilityFormSchema;
        this.conformityFormSchema             = conformityFormSchema;
        this.downloader                       = downloader;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.reports.manage',
            'legislation.client.reports.create',
            'legislation.client.reports.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        return this.fetchData().then(() => {
            this.applicabilityModel  = this.applicabilityFormSchema.model();
            this.applicabilitySchema = this.applicabilityFormSchema.schema(this);

            this.conformityModel  = this.conformityFormSchema.model();
            this.conformitySchema = this.conformityFormSchema.schema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params = null) {
        return Promise.all([
            this.companyOrganizationsRepository.tree(true).then(applicableSectorsTree => this.applicableSectorsTree = applicableSectorsTree),
            this.diplomaClassificationsRepository.tree(true).then(diplomaClassificationsTree => this.diplomaClassificationsTree = diplomaClassificationsTree),
        ]);
    }

    /**
     * Downloads applicability report
     */
    downloadApplicabilityReport() {
        this.repository.downloadApplicabilityReport(
            this.applicabilityModel,
            (response) => this.resetForms().then(() => this.downloader.download(response, 'applicability_report', 'pdf')),
            (response) => this.applicabilityAlert = this.alertMessage(response.status, response.message, response.errors),
        );
    }

    /**
     * Downloads applicability listing
     */
    downloadApplicabilityListing() {
        this.repository.downloadApplicabilityListing(
            this.applicabilityModel,
            (response) => this.resetForms().then(() => this.downloader.download(response, 'applicability_listing', 'xlsx')),
            (response) => this.applicabilityAlert = this.alertMessage(response.status, response.message, response.errors),
        );
    }

    /**
     * Downloads conformity listing
     */
    downloadConformityListing() {
        this.repository.downloadConformityListing(
            this.conformityModel,
            (response) => this.resetForms().then(() => this.downloader.download(response, 'conformity_listing', 'xlsx')),
            (response) => this.conformityAlert = this.alertMessage(response.status, response.message, response.errors),
        );
    }

    /**
     * Resets forms
     *
     * @returns {Promise<boolean>}
     */
    resetForms() {
        this.applicabilityAlert = null;
        this.conformityAlert    = null;

        return Promise.resolve(true);
    }

}
