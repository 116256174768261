import { inject }                                 from 'aurelia-framework';
import { PlanActionConformityStatusesRepository } from 'modules/legislation/management/plan-action-conformity-statuses/services/repository';
import { PlanActionTaskStatus }                   from 'modules/legislation/models/plan-action-task-status';
import { MyAction }                               from 'modules/legislation/client/personal-area/models/my-action';
import { ConcludeTaskModal }                      from 'modules/legislation/client/personal-area/my-actions/edit/conclude/index';
import { CancelActionDialog }                     from 'modules/legislation/client/personal-area/my-actions/edit/dialogs/cancel';
import { ConcludeActionDialog }                   from 'modules/legislation/client/personal-area/my-actions/edit/dialogs/conclude';
import { DialogService }                          from 'aurelia-dialog';

@inject(PlanActionConformityStatusesRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param planActionConformityStatusesRepository
     * @param dialogService
     */
    constructor(planActionConformityStatusesRepository, dialogService) {
        this.planActionConformityStatusesRepository = planActionConformityStatusesRepository;
        this.dialogService                          = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {MyAction}
     */
    model() {
        let model = new MyAction();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns action reply form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    actionReplySchema(viewModel) {
        this.conformity_status_id = {
            type:         'select2',
            key:          'conformity_status_id',
            label:        'form.field.conformity',
            size:         4,
            options:      [],
            remoteSource: this.planActionConformityStatusesRepository.active.bind(this.planActionConformityStatusesRepository),
        };

        this.implemented_at = {
            key:   'implemented_at',
            type:  'material-ui-date-picker',
            label: 'form.field.implementation-date',
            size:  4,
        };

        this.observations = {
            type:  'textarea',
            key:   'observations',
            label: 'form.field.observations',
            size:  12,
        };

        return [
            [this.conformity_status_id, this.implemented_at],
            [this.observations],
        ];
    }

    /**
     * Returns global buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalButtonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('legislation.client.personal-area.my-actions.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.save(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.cancelButton = {
            type:       'submit',
            label:      'form.button.cancel',
            hidden:     viewModel.model.status_id !== PlanActionTaskStatus.ASSIGNED,
            action:     () => {
                this.dialogService
                    .open({viewModel: CancelActionDialog})
                    .whenClosed((response) => {
                        if (!response.wasCancelled) {
                            viewModel.cancel();
                        }
                    });
            },
            attributes: {
                class: 'btn btn-warning',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.concludeButton = {
            type:       'submit',
            label:      'form.button.conclude',
            hidden:     viewModel.model.status_id !== PlanActionTaskStatus.ASSIGNED,
            action:     () => {
                this.dialogService.open({
                    viewModel: ConcludeTaskModal,
                    model:     viewModel.model,
                }).whenClosed((response) => {
                    if (!response.wasCancelled) {
                        this.dialogService
                            .open({viewModel: ConcludeActionDialog})
                            .whenClosed((confirmationResponse) => {
                                if (!confirmationResponse.wasCancelled) {
                                    viewModel.conclude();
                                }
                            });
                    }
                });
            },
            attributes: {
                class: 'btn btn-success',
            },
            icon:       {
                attributes: {
                    class: 'icon-checkmark3',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
                this.cancelButton,
                this.concludeButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
