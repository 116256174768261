import { inject, PLATFORM }          from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { CreateDiploma }             from 'modules/legislation/diplomas/create/index';
import { ClientDiplomasRepository }  from 'modules/legislation/client/diplomas/services/repository';
import { DiplomaSettingsRepository } from 'modules/legislation/management/diploma-settings/services/repository';
import { ClientDiplomaFormSchema }   from 'modules/legislation/client/diplomas/form-schema';

@inject(AppContainer, ClientDiplomasRepository, DiplomaSettingsRepository, ClientDiplomaFormSchema)
export class CreateClientDiploma extends CreateDiploma {

    formId = 'legislation-client-diplomas-creation-form';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param settingsRepository
     * @param formSchema
     */
    constructor(appContainer, repository, settingsRepository, formSchema) {
        super(appContainer, repository, settingsRepository, formSchema);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/legislation/diplomas/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.create',
        ]);
    }

}
