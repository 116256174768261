import { BooleanOption } from 'modules/administration/models/boolean-option';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        next_task_implementation_deadline: null,
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {{}}
     */
    model() {
        return this.modelDefaults;
    }

    /**
     * Returns action reply form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.create_next_task = {
            key:       'create_next_task',
            type:      'boolean-options-select',
            label:     'form.field.create-next-task',
            size:      12,
            observers: [
                (newValue) => this.next_task_implementation_deadline.instance.disable(newValue !== BooleanOption.YES),
            ],
        };

        this.next_task_implementation_deadline = {
            key:        'next_task_implementation_deadline',
            type:       'material-ui-date-picker',
            label:      'form.field.next-task-implementation-deadline',
            size:       12,
            required:   false,
            attributes: {
                disabled: viewModel.model.create_next_task !== BooleanOption.YES,
            },
        };

        return [
            [this.create_next_task],
            [this.next_task_implementation_deadline],
        ];
    }

}
