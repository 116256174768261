import { inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { CustomDialog }     from 'resources/elements/html-elements/dialogs/custom-dialog';
import { DialogController } from 'aurelia-dialog';

@inject(AppContainer, DialogController)
export class ConcludeActionDialog extends CustomDialog {

    defaultSettings = {
        title:         'dialog.title.conclude-action',
        body:          'dialog.body.conclude-action',
        buttons:       false,
        customButtons: [],
        cancelButton:  {
            class:  'btn-link',
            action: this.cancel.bind(this),
            label:  'dialog.button.cancel',
        },
        okButton:      {
            class:  'btn-primary',
            action: this.submit.bind(this),
            label:  'dialog.button.conclude-action',
        },
    };

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/elements/html-elements/dialogs/custom-dialog.html');
    }

    /**
     * Handles activate event
     */
    activate(settings) {
        super.activate(settings);

        this.settings.customButtons.push(this.settings.cancelButton);
        this.settings.customButtons.push(this.settings.okButton);
    }

}
