import { bindable, inject, PLATFORM } from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { ListDiplomas }               from 'modules/legislation/diplomas/index';
import { ClientDiplomasRepository }   from 'modules/legislation/client/diplomas/services/repository';
import { DiplomaSettingsRepository }  from 'modules/legislation/management/diploma-settings/services/repository';
import { ViewDiplomaModal }           from 'modules/legislation/diplomas/view-modal/index';
import { DialogService }              from 'aurelia-dialog';
import { FilterFormSchema }           from 'modules/legislation/diplomas/filter-form-schema';

@inject(AppContainer, ClientDiplomasRepository, DiplomaSettingsRepository, DialogService, FilterFormSchema)
export class ListClientDiplomas extends ListDiplomas {

    listingId = 'legislation-client-diplomas-listing';

    @bindable newRecordRoute = 'legislation.client.diplomas.create';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param diplomaSettingsRepository
     * @param dialogService
     * @param filterFormSchema
     */
    constructor(appContainer, repository, diplomaSettingsRepository, dialogService, filterFormSchema) {
        super(appContainer, repository, diplomaSettingsRepository, dialogService, filterFormSchema);
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'legislation.client.diplomas.manage',
            'legislation.client.diplomas.view',
        ]);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:           'resource.legislation.diploma',
            repository:         this.repository,
            edit:               {
                icon:    'icon-pencil7',
                tooltip: 'form.button.edit',
                visible: (row) => this.appContainer.authenticatedUser.can('legislation.diplomas.manage') || !row.isSticky,
                action:  (row) => this.redirectToRoute({
                    name:   'legislation.client.diplomas.edit',
                    params: {
                        id: row.id,
                    },
                }),
            },
            destroy:            {
                icon:    'icon-trash',
                tooltip: 'form.button.delete',
                visible: (row) => this.appContainer.authenticatedUser.can('legislation.diplomas.manage') || !row.isSticky,
                action:  (row) => this.datatable.instance.doDestroy(row),
            },
            show:               {
                action: (row) => this.dialogService.open({
                    viewModel: ViewDiplomaModal,
                    model:     row,
                }),
            },
            actionsContextMenu: true,
            actions:            [
                {
                    icon:    'icon-pushpin',
                    tooltip: 'form.button.applicability',
                    action:  (row) => this.redirectToRoute({
                        name:   'legislation.client.applicability.analysis.perform',
                        params: {
                            id: row.id,
                        },
                    }),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma-sheet',
                    action:  (row) => this.repository.downloadDiplomaSheet(row.id),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.diploma',
                    visible: (row) => row.viewingDocument !== null,
                    action:  (row) => this.repository.downloadFile(row.viewingDocument),
                },
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.consolidated-document',
                    visible: (row) => row.consolidatedDocument !== null,
                    action:  (row) => this.repository.downloadFile(row.consolidatedDocument),
                },
            ],
            options:            [
                {
                    label:     'form.button.export-to-excel',
                    icon:      'icon-file-excel',
                    className: 'btn bg-success',
                    action:    () => this.repository.exportListingExcel(this.filterModel),
                },
            ],
            buttons:            [],
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'desc',
            },
            columns:            [
                {
                    data:      'name',
                    name:      'diplomas.name',
                    title:     'form.field.diploma',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-name/index'),
                    nowrap:    true,
                },
                {
                    data:       'summary',
                    name:       'diplomas.summary',
                    title:      'form.field.summary',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-summary/index'),
                    orderable:  false,
                },
                {
                    data:       'legal_connections',
                    name:       'legal_connections',
                    title:      'form.field.legal-connections',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/diploma-legal-connections/index'),
                    nowrap:     true,
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:  'status',
                    name:  'diploma_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
                {
                    data:       'applicable',
                    name:       'applicabilities_count',
                    title:      '',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/legislation/diplomas/custom-listing-cells/applicable/index'),
                    searchable: false,
                    orderable:  false,
                },
            ],
        };
    }
}
