import { MyActionFollowup } from 'modules/legislation/client/personal-area/models/my-action-followup';

export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {MyActionFollowup}
     */
    model(viewModel) {
        let model = new MyActionFollowup();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.overview = {
            type:  'textarea',
            key:   'overview',
            id:    'plan_action_task_execution_followup_overview',
            label: 'form.field.overview',
            size:  12,
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal float-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.overview],
            [this.buttons],
        ];
    }

}
