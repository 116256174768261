import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class MyActionFollowupsRepository {

    baseUrl = 'legislation/client/personal-area/my-actions';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Performs a search given some criteria
     *
     * @param planActionTaskExecutionId
     * @param criteria
     *
     * @returns {*}
     */
    search(planActionTaskExecutionId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${planActionTaskExecutionId}/followups/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param planActionTaskExecutionId
     * @param data
     *
     * @returns {*}
     */
    create(planActionTaskExecutionId, data) {
        return this.httpClient.post(`${this.baseUrl}/${planActionTaskExecutionId}/followups`, data);
    }

    /**
     * Destroys a record
     *
     * @param id
     *
     * @returns {*}
     */
    destroy(id) {
        return this.httpClient.delete(`${this.baseUrl}/followups/${id}`);
    }

    /**
     * Destroys selected records
     *
     * @param ids
     *
     * @returns {*}
     */
    destroySelected(ids) {
        return this.httpClient.delete(`${this.baseUrl}/followups/destroy-selected`, {ids: ids});
    }

}
